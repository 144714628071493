<template>
  <el-dialog v-model="dialogVisible" title="條件搜尋" width="650px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="search-form"
    >
      <el-form-item class="form-item">
        <template #label>
          <span class="label">狀態</span>
        </template>
        <el-select v-model="ruleForm.available_status" placeholder="請選擇狀態" clearable style="width: 100%">
          <el-option label="未開始" value="未開始" />
          <el-option label="進行中" value="進行中" />
          <el-option label="已結束" value="已結束" />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" prop="date">
        <template #label>
          <span class="label">活動日期</span>
        </template>
        <el-date-picker
          v-model="ruleForm.date"
          type="daterange"
          unlink-panels
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          :shortcuts="shortcuts"
          format="YYYY/MM/DD"
          value-format="YYYY/MM/DD"
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">活動ID</span>
        </template>
        <el-input v-model="ruleForm.event_id" placeholder="請輸入活動ID" />
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">活動名稱</span>
        </template>
        <el-input v-model="ruleForm.event_name" placeholder="請輸入活動名稱" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="handleSearch(ruleFormRef)"
          :disabled="!checkSubmit()"
          style="margin-left: 25px"
        >
          搜尋
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref } from 'vue';
import { useGetters, useActions, useMutations } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
export default {
  name: 'search-news-dialog',
  setup() {
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //設定初始值
    const getSearchCondition = useGetters('news', ['getSearchCondition']);
    if (dialogVisible) {
      ruleForm.value = JSON.parse(JSON.stringify(getSearchCondition.value));
    }

    //日期相關-----------------------------------------------------
    //日期快捷
    const shortcuts = [
      {
        text: '當天',
        value: () => {
          const end = new Date();
          const start = new Date();
          return [start, end];
        },
      },
      {
        text: '當周',
        value: () => {
          const end = new Date();
          const start = new Date(end.getTime() - 3600 * 1000 * 24 * (end.getDay() === 0 ? 6 : end.getDay() - 1));
          return [start, end];
        },
      },
      {
        text: '當月',
        value: () => {
          const end = new Date();
          const start = new Date(`${end.toLocaleDateString().slice(0, 7)}/01`);
          return [start, end];
        },
      },
    ];

    //驗證與檢查-----------------------------------------------------------------
    const checkSubmit = () => {
      let isValid;
      //未選擇任一條件
      isValid = !Object.keys(ruleForm.value).every((e) => !ruleForm.value[e] || ruleForm.value[e].length === 0);
      //未改變任一條件
      if (getSearchCondition.value && isValid) {
        isValid = !(JSON.stringify(ruleForm.value) === JSON.stringify(getSearchCondition.value));
      }
      return isValid;
    };

    //送出
    const router = useRouter();
    const { doGetNewsList, doGetNewsSummary } = useActions('news', ['doGetNewsList', 'doGetNewsSummary']);
    const SET_SEARCH_CONDITION = useMutations('news', ['SET_SEARCH_CONDITION']);
    const handleSearch = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        // console.log(ruleForm.value);
        SET_SEARCH_CONDITION(ruleForm.value);
        await doGetNewsList({ ...ruleForm.value, start: 0 });
        await doGetNewsSummary(ruleForm.value);
        router.push('/information/news?page=1');
        close();
      } catch (e) {
        e;
      }
    };
    return {
      dialogVisible,
      close,
      ruleFormRef,
      ruleForm,
      shortcuts,
      checkSubmit,
      handleSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
